import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const { t, i18n } = useTranslation();

  const goToWebsite = () => {
    if (i18n.language === 'fr') {
      window.location.href = 'https://www.cyclock.co/token';
    } else if (i18n.language === 'en') {
      window.location.href = 'https://www.cyclock.co/token-en';
    }
    return null;
  };

  const goToWhitepaper = () => {
    if (i18n.language === 'fr') {
      window.location.href = 'https://uploads-ssl.webflow.com/622b59ce9d101168ce6860e4/631a1ba1776134569c274e90_LivreBlancFR_MaJ_29-09-22_compressed.pdf';
    } else if (i18n.language === 'en') {
      window.location.href = 'https://uploads-ssl.webflow.com/622b59ce9d101168ce6860e4/631a1ba0067a2707020aa188_Whitepaper_EN_MaJ_07-09-2022_compressed.pdf';
    }
    return null;
  };

  return (
    <>
      <div className="flex flex-col w-full items-center lg:w-1/2 lg:mt-[1%]">
        <p className="font-kulim font-bold text-center text-3xl px-10 mb-4">{t('Le stationnement')} <span className="text-cyan-cyclock">MultiversX</span></p>
        <p className="text-center px-4 lg:text-sm">{t('cyclock-create')}</p>
        <p className="text-center px-4 mb-6 lg:text-sm">{t('participate')}</p>
        <div className="flex flex-col lg:inline">
          <button className="border border-solid border-white rounded-full px-10 py-5 mb-6 lg:mr-12 lg:px-5 lg:py-2 lg:text-sm" onClick={goToWhitepaper}>WHITE PAPER</button>
          <button className="border border-solid border-white rounded-full px-10 py-5 lg:px-5 lg:py-2 lg:text-sm" onClick={goToWebsite}>WEBSITE</button>
        </div>
        <div className="absolute bottom-0 flex flex-col items-center mb-5 mt-5 lg:w-1/2 support-hidden-vertical">
          <iframe title='EGLD Community' src='https://egld.community/api/products/cf0100c0-f168-4a9d-88d0-7fb74e340dd1/upvotes/embed' className='rounded-lg border-0 w-[290px] h-[70px] mb-3'></iframe>
          <p className="lg:text-sm">{t('soutenez')}</p>
        </div>
      </div>
      <img alt='Bicycle' src={process.env.PUBLIC_URL + '/assets/velo.png'} className="absolute bottom-0 left-0 z-[-1] lg:w-1/2"/>

    </>
  );
};

export default Home;
