import { EnvironmentsEnum } from "@multiversx/sdk-dapp/types";

export const dAppName = "CYC'LOCK";

export const environment = EnvironmentsEnum.mainnet;

export const walletConnectV2ProjectId = "f7439bbc3e9b13ab4cacfd4c2c315c3d";
export const networkConfig = {
  name: "customConfig",
  apiTimeout: 6000,
  walletConnectV2ProjectId,
};

export const scAddress =
  "erd1qqqqqqqqqqqqqpgqupfk52kznaj5xcx9t8r3hqwjrjkcj9n6hwnqs8wxss";

export const apiUrl = "https://api.multiversx.com";
export const chainID = "1";
