import "./index.css";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import endpoints from "../../contract/endpoints";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";

function Admin() {
  const { address } = useGetAccountInfo();
  const [, setTxID] = useState<string | null>(null);
  const [csvParsed, setCsvParsed] = useState<any[]>([]);

  useEffect(() => {
    if (!isAdmin()) {
      window.location.href = "https://dapp.cyclock.co";
    }
  }, []);

  const isAdmin = () => {
    return (
      address ===
        "erd1ncy99lzkk5u8djsypxnyykqztpkc96jm4ummk58h7tnseg2er28s3k7afw" ||
      address ===
        "erd1h4uhy73dev6qrfj7wxsguapzs8632mfwqjswjpsj6kzm2jfrnslqsuduqu"
    );
  };

  const changeHandler = (event: any) => {
    Papa.parse(event.target.files[0], {
      header: false,
      delimiter: ",",
      quoteChar: "'",
      skipEmptyLines: true,
      transform: (value: any) => {
        value = value.replaceAll(/(\(|\)| |')/g, "");
        return value;
      },
      complete: (results: any) => {
        let newCsv: any = [];
        const resultOnlySuccess = [...results.data].filter(
          (row) => row[3] === "success"
        );
        resultOnlySuccess.forEach((array: any) => {
          if (!newCsv.find((find: any) => find[0] === array[1])) {
            newCsv.push([
              array[1],
              resultOnlySuccess
                .filter((filter: any) => filter[1] === array[1])
                .reduce((a: number, b: any) => a + parseInt(b[2]), 0),
            ]);
          }
        });
        newCsv = newCsv.map((map: any) => [map[0], map[1].toString()]);
        setCsvParsed(newCsv);
      },
    });
  };

  const sendCsvTransaction = async () => {
    const { sessionId, error } = await endpoints.addData(csvParsed);
    if (sessionId) {
      setTxID(sessionId);
    } else {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col self-center">
      {isAdmin() ? (
        <>
          <p>
            Envoyez le fichier a charger pour mettre a jour les montant des
            holders de CYC
          </p>
          <input
            type="file"
            accept=".csv"
            onChange={changeHandler}
            className="mt-5"
          />
          {csvParsed.length > 0 ? (
            <button
              onClick={sendCsvTransaction}
              className="btn-validate py-5 px-8 tracking-widest mt-5"
            >
              VALIDER
            </button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p>Unauthorized</p>
      )}
    </div>
  );
}

export default Admin;
