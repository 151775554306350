import React, { useEffect, memo } from 'react';

// eslint-disable-next-line react/display-name
const withPageTitle = (title: string, Component: React.ComponentType) => () => {
  const Memoized = memo(Component);

  useEffect(() => {
    document.title = title;
  }, []);

  return <Memoized />;
};

export default withPageTitle;
