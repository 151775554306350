import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { ResultsParser } from "@multiversx/sdk-core/out";
import { apiUrl } from "../config";
import { smartContract } from "./getSmartContract";

const getAmountByAddress = async (address: string) => {
  try {
    const proxy = new ProxyNetworkProvider(apiUrl);

    const interaction = smartContract.methods.getAmountByAddress([address]);
    const query = interaction.check().buildQuery();

    const queryResponse = await proxy.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();

    const { firstValue } = new ResultsParser().parseQueryResponse(
      queryResponse,
      endpointDefinition
    );
    return firstValue?.valueOf().toNumber();
  } catch (error) {
    return 0;
  }
};

const queries = {
  getAmountByAddress,
};

export default queries;
