import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { getAccountBalance } from "@multiversx/sdk-dapp/utils/account/getAccountBalance";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import queries from "../../contract/queries";
import "./index.css";

function Account() {
  const { t } = useTranslation();
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [egldBalance, setEgldBalance] = useState("0");
  const [cycBalance, setCycBalance] = useState("Loading");

  useEffect(() => {
    if (isLoggedIn) {
      getAccountBalance(address).then((result: string) => {
        setEgldBalance(
          BigNumber(result).dividedBy("1000000000000000000").toFixed(2)
        );
      });
      queries.getAmountByAddress(address).then((result) => {
        setCycBalance(BigNumber(result).dividedBy("1000000000").toFixed(2));
      });
    }
  }, [isLoggedIn, address]);

  return (
    <>
      <div className="flex flex-col px-12 gap-6 md:self-center md:px-0 md:w-9/12 h-full mb-5">
        <div className="flex flex-col gap-6 md:w-9/12 md:flex-row md:self-center md:mb-10 md:justify-between">
          <div className="token-div rounded-md px-16 py-3 text-center">
            <p className="text-sm">{t("egld-balance")}</p>
            <p className="font-kulim text-2xl">{egldBalance} EGLD</p>
          </div>
          <div className="token-div rounded-md px-16 py-3 text-center">
            <p className="text-sm">{t("cyc-balance")}</p>
            <p className="font-kulim text-2xl">{cycBalance} CYC</p>
          </div>
        </div>
        <div
          id="date-div"
          className="flex flex-row w-full gap-10 md:gap-16 overflow-scroll pb-10 md:pb-5 xl:justify-center"
        >
          <div className="flex flex-col text-center w-36 md:w-48">
            <p className="text-gradient-day text-6xl">01</p>
            <div className="w-36 md:w-48 h-20 md:h-auto flex items-center justify-center">
              <p className="text-gradient-month text-3xl mb-4">Sale/LP</p>
            </div>
            <p className="text-xs md:text-sm">{t("sale-lp")}</p>
          </div>
          <div className="flex flex-col text-center w-48">
            <p className="text-gradient-day text-6xl">02</p>
            <div className="w-36 md:w-48 h-20 md:h-auto flex items-center justify-center">
              <p className="text-gradient-month text-4xl mb-4">Claim CYC</p>
            </div>
            <p className="text-xs md:text-sm">{t("claim-cyc")}</p>
          </div>
          <div className="flex flex-col text-center w-48">
            <p className="text-gradient-day text-6xl">03</p>
            <div className="w-36 md:w-48 h-20 md:h-auto flex items-center justify-center">
              <p className="text-gradient-month text-4xl mb-4">R2E project</p>
            </div>
            <p className="text-xs md:text-sm">{t("r2e-project")}</p>
          </div>
          <div className="flex flex-col text-center w-48">
            <p className="text-gradient-day text-6xl">04</p>
            <div className="w-36 md:w-48 h-20 md:h-auto flex items-center justify-center">
              <p className="text-gradient-month text-3xl mb-4">Next steps.</p>
            </div>
            <p className="text-xs md:text-sm">{t("next-step")}</p>
          </div>
        </div>
        <div className="static 2xl:absolute bottom-0 flex flex-col items-center mb-5 mt-5 md:w-1/2 self-center support-hidden-vertical-account">
          <iframe
            title="EGLD Community"
            src="https://egld.community/api/products/cf0100c0-f168-4a9d-88d0-7fb74e340dd1/upvotes/embed"
            className="rounded-lg border-0 w-[290px] h-[70px] mb-3"
          ></iframe>
          <p className="text-center">{t("soutenez")}</p>
        </div>
      </div>
    </>
  );
}

export default Account;
