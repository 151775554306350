import { AuthenticatedRoutesWrapper } from "@multiversx/sdk-dapp/wrappers";
import React, { FC, ReactNode } from "react";
import Header from "./Header";
import routes from "../../routes";
import "./index.css";

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="grow flex flex-col justify-between md:items-end ">
        <AuthenticatedRoutesWrapper routes={routes} unlockRoute={"/"}>
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
    </div>
  );
};

export default Layout;
