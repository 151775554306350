import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Le stationnement': 'The bicycle parking powered by',
      'cyclock-create': "Cyc'lock creates smart city services based on the MultiversX blockchain.",
      participate: 'Participate in the creation of its first project: the construction of a bicycle parking network.',
      'cyc-balance': 'Your CYC balance',
      'egld-balance': 'Your EGLD balance',
      'sale-lp': 'The final plan to complete the pre-sale of the $CYC token is to have a sales phase of the token on a launchpad platform. This phase will be followed by the listing of the token and the launch of the tokenomics!',
      'claim-cyc': 'Beginning of the distribution phase of the tokens according to the vesting schedule. Buyers will be able to claim their tokens on a monthly basis to mint the NFT network or stake their CYC and receive rewards.',
      'r2e-project': 'Publication of the white paper, roadmap and start of the communication on the ride to earn project. Start of the private sale of the R2E token. Start of the R2E token sale.',
      'next-step': 'Extension of the network and increase of the Supply of NFT network in circulation, start of the buy back and burn of CYC according to the use of the network. Reveal of the Bike Marketplace. Launch of the bike warranty and associated staking.',
      soutenez: 'Support the project by upvoting'
    }
  },
  fr: {
    translation: {
      'Le stationnement': 'Le stationnement vélo propulsé par',
      'cyclock-create': "Cyc’lock crée des services de smart city en s'appuyant sur la blockchain MultiversX.",
      participate: 'Participez à la création de son 1er Projet : la construction d’un réseau de stationnement pour vélos.',
      'cyc-balance': 'Votre solde de CYC',
      'egld-balance': 'Votre solde de EGLD',
      'sale-lp': 'Le plan final pour mener à bien la prévente du token $CYC vise à avoir une phase de vente de ce dernier sur une plateforme de launchpad. Cette phase sera suivie du listing du token et du lancement de la tokenomics!',
      'claim-cyc': 'Début de la phase de distribution des tokens selon le calendrier de vesting. Les acheteurs pourront claim leurs tokens mensuellement pour minter les NFT network ou staker leur CYC et recevoir des rewards. ',
      'r2e-project': 'Publication du White paper, de la roadmap et début de la communication sur le projet ride to earn. Début de la private sale du token R2E. Début de la vente du token R2E.',
      'next-step': "Extension du réseau et augmentation de la Supply de NFT network en circulation, début du buy back and burn de CYC selon l'usage du réseau. Reveal de la Marketplace vélo. Lancement de la garantie vélo et du staking associé. ",
      soutenez: 'Soutenez le projet en upvotant'
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
