import {
  AbiRegistry,
  Address,
  SmartContract,
  SmartContractAbi,
} from "@multiversx/sdk-core/out";
import json from "./abi/cyc.abi.json";
import { scAddress } from "../config";

const abiRegistry = AbiRegistry.create(json);
const abi = new SmartContractAbi(abiRegistry);

export const smartContract = new SmartContract({
  address: new Address(scAddress),
  abi,
});
