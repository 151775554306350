import React, { useEffect } from "react";
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
} from "@multiversx/sdk-dapp/UI";
import { getIsLoggedIn } from "@multiversx/sdk-dapp/utils";
import { routeNames } from "../../routes";
import "./index.css";

export const UnlockPage = () => {
  const isLoggedIn = getIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.account;
    }
  }, [isLoggedIn]);

  return (
    <div className="flex flex-col gap-4 mb-6 mt-6 items-center">
      <h1 className="font-kulim text-3xl">
        Connect <span className="text-[#27BB9F]">Wallet</span>
      </h1>
      <ExtensionLoginButton
        callbackRoute={routeNames.account}
        className="btn-connect"
      >
        <span>Maiar Defi Wallet</span>
      </ExtensionLoginButton>
      <WalletConnectLoginButton
        callbackRoute={routeNames.account}
        logoutRoute="/"
        className="btn-connect"
        wrapContentInsideModal={true}
        isWalletConnectV2={true}
      >
        <span>Maiar</span>
      </WalletConnectLoginButton>
      <LedgerLoginButton
        callbackRoute={routeNames.account}
        className="btn-connect"
        wrapContentInsideModal={true}
      >
        <span>Ledger</span>
      </LedgerLoginButton>
      <WebWalletLoginButton
        callbackRoute={routeNames.account}
        className="btn-connect"
      >
        <span>Web Wallet</span>
      </WebWalletLoginButton>
    </div>
  );
};

export default UnlockPage;
