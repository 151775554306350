import withPageTitle from './components/PageTitle';
import { dAppName } from './config';
import Home from './pages/home';
import Account from './pages/account';
import Admin from './pages/admin';

export const routeNames = {
  home: '/',
  account: '/account',
  admin: '/admin'
};

const routes: any[] = [
  {
    path: routeNames.home,
    title: 'HOME',
    component: Home
  },
  {
    path: routeNames.account,
    title: 'ACCOUNT',
    component: Account,
    authenticatedRoute: true
  },
  {
    path: routeNames.admin,
    title: 'ADMIN',
    component: Admin,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${dAppName} • ${route.title}` : dAppName;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
