import React, { useState } from "react";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";

import "./index.css";
import Modal from "react-modal";
import UnlockPage from "../../../pages/unlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";

const Header = () => {
  const { i18n } = useTranslation();

  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState<boolean>(false);

  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const openLoginMenu = () => {
    closeMenu();
    setIsLoginMenuOpen(true);
  };
  const closeLoginMenu = () => {
    setIsLoginMenuOpen(false);
  };

  const changeLanguage = (event: any) => {
    i18next.changeLanguage(event.target.dataset.lang, (err) => {
      if (err) {
        return console.log("something went wrong loading", err);
      }
    });
  };

  const truncAddr = (nbLeadChar: number) => {
    return (
      <p>
        {address.slice(0, nbLeadChar) +
          "..." +
          address.slice(address.length - nbLeadChar)}
      </p>
    );
  };

  return (
    <>
      <Modal
        isOpen={isLoginMenuOpen}
        onRequestClose={closeLoginMenu}
        className="login-modal"
        overlayClassName="login-overlay"
        ariaHideApp={false}
      >
        <UnlockPage></UnlockPage>
      </Modal>
      <header className="flex justify-between p-5 md:p-16">
        <div className="flex items-center">
          <Link to="/">
            <img
              alt="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
              className="w-16"
            />
          </Link>
          <Link to="/">
            <h2 className="font-kulim ml-2.5">CYC&apos;LOCK</h2>
          </Link>
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="w-10 h-10 md:hidden cursor-pointer"
          onClick={openMenu}
        />
        <div className="hidden md:flex items-center gap-8">
          {isLoggedIn ? (
            <Link to="/account">{truncAddr(8)}</Link>
          ) : (
            <>
              <p className="text-lg cursor-pointer hidden">Section 1</p>
              <p className="text-lg cursor-pointer hidden">Section 2</p>
              <p className="text-lg cursor-pointer hidden">Section 3</p>
            </>
          )}
          <div className="flex justify-between">
            <p
              className={`text-lg mr-3 cursor-pointer ${
                i18n.resolvedLanguage === "fr"
                  ? "underline underline-offset-[5px]"
                  : ""
              }`}
              onClick={changeLanguage}
              data-lang="fr"
            >
              FR
            </p>
            <p
              className={`text-lg cursor-pointer ${
                i18n.resolvedLanguage === "en"
                  ? "underline underline-offset-[5px]"
                  : ""
              }`}
              onClick={changeLanguage}
              data-lang="en"
            >
              EN
            </p>
          </div>
          {isLoggedIn ? (
            <button
              className="btn-login py-3 px-8 tracking-widest"
              onClick={handleLogout}
            >
              DISCONNECT
            </button>
          ) : (
            <button
              className="btn-login py-3 px-8 tracking-widest"
              onClick={openLoginMenu}
            >
              LOGIN
            </button>
          )}
        </div>
        {isMenuOpen ? (
          <div className="fixed h-full w-full z-10 top-0 left-0 flex flex-col items-center bg-[#0000008C] backdrop-blur-md">
            <FontAwesomeIcon
              onClick={closeMenu}
              className="self-end mt-8 mr-8 text-3xl cursor-pointer"
              icon={faXmark}
            />
            {isLoggedIn ? (
              <Link to="/account">
                <p className="text-2xl mt-9">{truncAddr(8)}</p>
              </Link>
            ) : (
              <>
                <p className="text-2xl mt-9 hidden">Section 1</p>
                <p className="text-2xl mt-9 hidden">Section 2</p>
                <p className="text-2xl mt-9 hidden">Section 3</p>
              </>
            )}
            <div className="flex justify-between w-2/12 mt-16">
              <p
                className={`text-2xl ${
                  i18n.resolvedLanguage === "fr"
                    ? "underline underline-offset-[5px]"
                    : ""
                }`}
                onClick={changeLanguage}
                data-lang="fr"
              >
                FR
              </p>
              <p
                className={`text-2xl ${
                  i18n.resolvedLanguage === "en"
                    ? "underline underline-offset-[5px]"
                    : ""
                }`}
                onClick={changeLanguage}
                data-lang="en"
              >
                EN
              </p>
            </div>
            {isLoggedIn ? (
              <button
                className="btn-login py-5 px-8 tracking-widest mt-16"
                onClick={handleLogout}
              >
                DISCONNECT
              </button>
            ) : (
              <button
                className="btn-login py-5 px-8 tracking-widest mt-16"
                onClick={openLoginMenu}
              >
                LOGIN
              </button>
            )}
          </div>
        ) : (
          <></>
        )}
      </header>
    </>
  );
};

export default Header;
