import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { chainID } from "../config";
import { smartContract } from "./getSmartContract";

const addData = async (data: string[]) => {
  const numberPerTx = 300;
  const numberIteration = Math.ceil(data.length / numberPerTx);
  const tx = [];
  for (let i = 0; i < numberIteration; i++) {
    const gas = 350_000_000; // 50_000_000 + (data.slice(i * numberPerTx, (i + 1) * numberPerTx).length * 500_000);
    const interaction = smartContract.methods
      .addData(data.slice(i * numberPerTx, (i + 1) * numberPerTx))
      .withGasLimit(gas)
      .withChainID(chainID);

    tx.push(interaction.buildTransaction());
  }

  if (tx !== null) {
    const { sessionId, error } = await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: "Mise a jour...",
        errorMessage: "An error has occured",
        successMessage: "Mise a jour réussi.",
      },
    });

    return { sessionId, error };
  }
  return { sessionId: null, error: "tx invalid" };
};

const endpoints = { addData };

export default endpoints;
